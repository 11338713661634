<template>
  <v-card class="card-shadow border" color="white">
    <div>
      <v-card-title v-if="errorMessage" class="error-sec-btn mb-2">
        {{ errorMessage }}
      </v-card-title>
      <!-- Search input field -->
      <v-row align="end" class="pa-8" style="gap: 30px">
        <v-text-field v-model="accountNumber" append-icon="mdi-magnify" :label="'Search ' + (accountNumber ? (accountNumber.includes(',') ? 'Account Numbers' : 'Account Number') : '')" single-line hide-details />
        <v-btn color="primary" @click="fetchUserByAccountNumber()">
          Search
        </v-btn>
      </v-row>
      <!-- Data table -->
      <v-data-table :headers="headers" :items="accountData" hide-default-footer :loading="loading"
                    :loading-text="message" :items-per-page="currentRows" />
      <!-- Rows per page and pagination -->
      <v-row align="center" justify="end">
        <v-col cols="auto">
          <v-select style="width: 60px;" :items="rowsPerPage" @change="handleChangeRows" v-model="currentRows" />
        </v-col>
        <v-col cols="auto" style="min-width: 400px;">
          <v-pagination @input="pageChange" color="black" v-model="currentPage" :length="noOfPages"
                        :total-visible="7" />
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'FindAccPage',
  data() {
    return {
      accountNumber: '',
      currentPage: 1,
      rowsPerPage: [10, 15, 30, 50, 100],
      currentRows: 50,
      accountData: [],
      errorMessage: '',
      message: '',
      loading: false,
      headers: [
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Email', value: 'email' },
        { text: 'Address', value: 'address_1' },
      ],
    };
  },
  computed: {
    noOfPages() {
      return Math.ceil(this.accountData.length / this.currentRows);
    },
  },
  props: {
    currentServer: Object,
  },
  methods: {
    async fetchUserByAccountNumber() {

    try {
      // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
      const baseurl = process.env.VUE_APP_API_BASE_URL

      const response = await fetch(`${baseurl}/admin/reporting/find_user_by_account_number?last_four_digits=${this.accountNumber}`);

      const data = await response.json()
      this.data = JSON.stringify(response)

      if(response.status === 200){
      this.accountData = data.flat();
      setTimeout(() => {
          this.successMessage = '';
      }, 8000);

      }
      else {
      this.errorMessage = data.error;
      setTimeout(() => {
          this.errorMessage = '';
      }, 8000);
      }

    } catch (error) {
      console.error('Error fetching user data:', error);
      this.message = error.message;
    }
  },
    pageChange(page) {
      this.currentPage = page;
    },
    handleChangeRows() {
      this.currentPage = 1;
    },
  },
};
</script>


<style>

.big-input {
  width: 30%;
  padding: 12px;
  font-size: 18px; 
  border: 1px solid #ccc; 
  border-radius: 5px; 
  margin-right: 10px; 
}
.Heading{
  font-size: 30px;
  text-align: center
}

/* Style for search button */
.search-button {
  padding: 12px 20px; /* Adjust padding as needed */
  background-color: #007bff; /* Button background color */
  color: white; /* Button text color */
  border: none; /* Remove button border */
  border-radius: 5px; /* Add button border radius */
  cursor: pointer; /* Add pointer cursor on hover */
}

/* Style for input group */
.input-group {
  display: flex; /* Use flexbox to align items */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
}
.user-table {
  margin: 50px auto; 

  /* border-collapse: collapse; */
}

.user-table th, .user-table td {
  border: 1px solid black;
  padding: 8px;
}

.user-table th {
font-size: 20px;
  color: black;
  font-weight: 500;
}
.error-message {
  color: red;
}
</style>